@import '../partials';

.footer {
  background-color: $black;
  color: $white;
  display: flex;
  justify-content: center;

  a {
    color: $white;
  }

  &__boundary {
    padding: $component-spacing;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;

    @include bp(md) {
      flex-basis: auto;
    }
  }

  &__flex {
    display: flex;
    flex-direction: row;
    width: 100%;

    @include bp(md) {
      padding-left: rem(45);
    }
  }

  &__wrapper {
    margin-top: rem(50);
    line-height: rem(22);
    min-width: rem(215);
    flex-basis: auto;
    margin-right: rem(59);
    box-sizing: content-box;

    @include bp(lg) {
      margin-right: rem(139);
    }
  }

  &__logo {
    flex-basis: 100%;
    flex-shrink : 0;

    a {
      display: block;
      max-width: rem(400);

      @include bp(sm) {
        display: inline-block;
        max-width: 100%;
      }
    }
  }

  &__utility-menu {
    list-style: none;
    margin-bottom: rem(16);
    font-size: rem(18);
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    line-height: rem(29);
    margin-bottom: rem(4);
  }

  &__menu {
    display: none;
    flex: 1 1 auto;

    @include bp(footer) {
      display: block;
    }

    > ul {
      list-style: none;
      font-size: rem(18);
      font-weight: $font-weight-heavy;
      line-height: 1.57;
      text-transform: uppercase;
      display: flex;
      flex-wrap: wrap;
      font-family: $sans-serif-font;
      justify-content: flex-start;
      align-content: flex-start;
      max-height: rem(900);
      flex-direction: column;
      padding-top: rem(40);

      @include bp(lg) {
        max-height: rem(475);
      }

      > li {
        padding-right: rem(54);
        //max-width: rem(215);
        flex-basis: 50%;

        > a {
          display: block;
          margin-bottom: rem(13);
        }

        @include bp(lg) {
          padding-right: rem(24);
          flex-basis: 25%;
        }

        ul {
          list-style: none;
          font-size: rem(14);
          font-weight: $font-weight-normal;
          text-transform: none;
          font-family: $sans-serif-body-font;
          line-height: rem(22);
          padding-bottom: rem(40);

          li:not(:last-child) {
            margin-bottom: rem(18);
          }
        }
      }
    }
  }

  &__info {

  }

  &__copyright {
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: rem(0.83);
    font-weight: $font-weight-normal;
  }

  &__address {
    font-size: rem(12);
    letter-spacing: rem(1);
    line-height: rem(16);
    font-weight: $font-weight-normal;
    text-transform: uppercase;
  }

  &__phone {
    font-size: rem(12);
    letter-spacing: rem(1);
    margin-bottom: rem(16);
    font-weight: $font-weight-normal;
  }

  .social-icons {
    margin: rem(24) 0;

    &__links {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: flex-start;
      height: rem(19);
      margin-left: rem(-10);
    }

    &__link {
      margin-right: rem(3);

      a {
        padding: rem(10);
      }
    }
  }
}

_:-ms-fullscreen, :root .footer__menu ul {
  display: inline-block;
}

.cky-consent-container {
  @media (max-width: 576px) {
    z-index: 2147483648 !important;
  }
}